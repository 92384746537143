import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import MapContainer from "@/components/MapContainer.vue";
import bus from "@/plugins/bus";
export default {
  name: "parkAdd",
  components: {
    MapContainer
  },
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        parkSp: 1,
        thresholdStatus: 1,
        parkType: '定位范围'
      },
      formRules: {
        parkName: [{
          required: true,
          message: '请输入站点名称',
          trigger: 'blur'
        }],
        parkArea: [{
          required: true,
          message: '请选择所属区域',
          trigger: 'blur'
        }],
        parkType: [{
          required: true,
          message: '请选择范围方式',
          trigger: 'blur'
        }],
        parkCapacity: [{
          required: true,
          message: '请输入站点容量',
          trigger: 'blur'
        }]
      },
      isAdd: false,
      address: null,
      parkType: '',
      type: 'ADD',
      areas: [],
      //区域
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      }
    };
  },
  methods: {
    Close(s) {
      this.isAdd = s;
    },
    getAreas() {
      this.areas = [];
      this.$api.uac.area.list().then(res => {
        this.areas = res.data;
      });
    },
    getAddressData(address) {
      console.log(address);
      this.formModel.parkLongitude = address.lng;
      this.formModel.parkLatitude = address.lat;
      this.formModel.parkAdd = address.address;
      if (this.formModel.parkType === '区域范围') {
        this.formModel.paths = address.paths;
      }
      if (this.formModel.parkType === '定位范围') {
        this.formModel.parkRange = address.radius;
      }
    },
    begin() {
      console.log("区域id");
      console.log(this.formModel.parkArea);
      if (!this.formModel.parkArea) {
        ElMessage.error("请先选择服务区域");
        return;
      }
      if (!this.formModel.parkType) {
        ElMessage.error("请先选择范围方式后绘制区域");
        return;
      } else {
        if (this.formModel.parkType === '定位范围') {
          this.parkType = '定位范围';
          this.address = {
            lng: this.formModel.parkLongitude,
            lat: this.formModel.parkLatitude,
            add: this.formModel.parkAdd,
            radius: this.formModel.parkRange,
            areaId: this.formModel.parkArea,
            id: this.formModel.id
          };
        } else {
          this.parkType = '区域范围';
          this.address = {
            add: this.formModel.parkAdd,
            lng: this.formModel.parkLongitude,
            lat: this.formModel.parkLatitude,
            paths: this.formModel.paths,
            areaId: this.formModel.parkArea,
            id: this.formModel.id
          };
        }
        this.isAdd = true;
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          console.log(this.formModel);
          if (this.edit) {
            this.$api.business.park.update(this.formModel).then(callBack);
          } else {
            this.$api.business.park.parkAdd(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    this.getAreas();
    if (this.$route.query.id) {
      this.edit = true;
      this.type = 'EDIT';
      const loading = ElLoading.service();
      this.$api.business.park.getById(JSON.stringify({
        id: this.$route.query.id
      })).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};